import styled from 'styled-components';

export const HomeWelcomeContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const TextContainer = styled.span`
    width: 75%;
    border-radius: 5px;
    box-shadow: 4px 4px 8px 0px rgba( 0, 0, 0, 0.2 );
    background-color: white;
    padding: 50px;
    text-align: center;
`;

export const TitleContainer = styled.span`
    font-size: 40px;
    font-weight: bold;
    margin: 30px auto;
    text-align: center;
`;