import styled from 'styled-components';

export const SignUpContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 49%;
  border-radius: 5px;
  box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
  background-color: white;
  padding: 50px;
`;

export const SignUpTitle = styled.h2`
  margin: 10px 0;
`;