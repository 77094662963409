import React from 'react';

import {
    HomeWelcomeContainer,
    TextContainer,
    TitleContainer
} from './home-welcome.styles';

const HomeWelcome = () => (
    <HomeWelcomeContainer>
        <TitleContainer>
            Magneτic and Superconducτing Maτerials Daτabase
        </TitleContainer>
        <TextContainer>
            A centralized database for magnetic and superconducting compounds,
            the Tau4 project provides a location for users to both gather information from
            and contribute to an ever growing wealth of knowledge.
        </TextContainer>
    </HomeWelcomeContainer>
);

export default HomeWelcome;