import React from "react";
import { withRouter } from "react-router-dom";

import CustomButton from "../custom-button/custom-button.component";
import SearchQuery from "../search-query/search-query.component";

import {
  AddPropertyContainer,
  AddPropertyBorderContainer,
  AddPropertyButtonContainer,
  ButtonContainer,
  CompoundSearchContainer,
  CompoundSearchQueryContainer,
  CompoundSearchTextContainer,
  CompoundSearchTitleContainer,
  CompoundSearchValueContainer,
  MinorTitleContainer,
  OutputItemsRowContainer,
  OutputItemsColumnContainer,
  OutputSelectionContainer,
  QueriesContainer,
  QueriesTitleContainer,
  SearchContainer,
  SearchMenuButtonContainer,
  SearchMenuContainer,
  SearchOverviewRowContainer,
  SearchOverviewContainer,
  TitleContainer
} from "./search-overview.styles";

class SearchOverview extends React.Component {
  state = {
    dashboardFocus: "advancedSearch"
  };

  handleClick(newFocus) {
    this.setState({
      dashboardFocus: newFocus
    });
  }

  render() {
    const { match, history } = this.props;
    return (
      <SearchOverviewContainer>
        <TitleContainer>SEARCH</TitleContainer>
        <SearchOverviewRowContainer>
          <SearchMenuContainer>
            <MinorTitleContainer></MinorTitleContainer>
            <SearchMenuButtonContainer
              onClick={() => this.handleClick("compoundSearch")}
            >
              Compound Search
            </SearchMenuButtonContainer>
            <SearchMenuButtonContainer
              onClick={() => this.handleClick("advancedSearch")}
            >
              Advanced Search
            </SearchMenuButtonContainer>
            <SearchMenuButtonContainer
              onClick={() => this.handleClick("outputSelection")}
            >
              Output Selection
            </SearchMenuButtonContainer>
          </SearchMenuContainer>
          {this.state.dashboardFocus === "compoundSearch" ? (
            <SearchContainer>
              <CompoundSearchContainer>
                <CompoundSearchTitleContainer>
                  COMPOUND SEARCH
                </CompoundSearchTitleContainer>
                <CompoundSearchQueryContainer>
                  <CompoundSearchTextContainer>
                    Enter chemical formula:
                  </CompoundSearchTextContainer>
                  <CompoundSearchValueContainer></CompoundSearchValueContainer>
                </CompoundSearchQueryContainer>
              </CompoundSearchContainer>
              <ButtonContainer>
                <CustomButton
                  onClick={() => history.push(`${match.path}/results`)}
                >
                  SEARCH
                </CustomButton>
              </ButtonContainer>
            </SearchContainer>
          ) : (
            <div></div>
          )}
          {this.state.dashboardFocus === "advancedSearch" ? (
            <SearchContainer>
              <QueriesContainer>
                <QueriesTitleContainer>ADVANCED SEARCH</QueriesTitleContainer>
                <SearchQuery />
                <SearchQuery />
                <SearchQuery />
                <AddPropertyContainer>
                  <AddPropertyBorderContainer>
                    <AddPropertyButtonContainer>+</AddPropertyButtonContainer>
                  </AddPropertyBorderContainer>
                </AddPropertyContainer>
              </QueriesContainer>
              <ButtonContainer>
                <CustomButton
                  onClick={() => history.push(`${match.path}/results`)}
                >
                  SEARCH
                </CustomButton>
              </ButtonContainer>
            </SearchContainer>
          ) : (
            <div></div>
          )}
          {this.state.dashboardFocus === "outputSelection" ? (
            <OutputSelectionContainer>
              <MinorTitleContainer>OUTPUTS</MinorTitleContainer>
              <OutputItemsRowContainer>
                <OutputItemsColumnContainer>
                  <p>☑ Curie temperature</p>
                  <p>☑ Neel temperature</p>
                  <p>☑ Superconducting temperature</p>
                  <p>☑ Entry prototype</p>
                  <p>☑ SGR symbol (std.)</p>
                  <p>☑ SGR number (std.)</p>
                  <p>☑ Inv. center</p>
                  <p>☑ Polar axis</p>
                  <p>☑ V (std.) [nm...]</p>
                </OutputItemsColumnContainer>
                <OutputItemsColumnContainer>
                  <p>☑ a [nm]</p>
                  <p>☑ b [nm]</p>
                  <p>☑ c [nm]</p>
                  <p>☑ alpha [degree]</p>
                  <p>☑ beta [degree]</p>
                  <p>☑ gamma [degree]</p>
                  <p>☑ Density (calc.) [Mg m%...]</p>
                  <p>☑ Crystal system (std.)</p>
                  <p>☑ Compound class</p>
                </OutputItemsColumnContainer>
              </OutputItemsRowContainer>
            </OutputSelectionContainer>
          ) : (
            <div></div>
          )}
        </SearchOverviewRowContainer>
      </SearchOverviewContainer>
    );
  }
}

export default withRouter(SearchOverview);
