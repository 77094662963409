import styled from "styled-components";

export const PropertyEntryContainer = styled.div`
  border-radius: 5px;
  box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
  background-color: white;
  padding: 15px;
  margin: 5px;
  width: 800px;
  display: flex;
  justify-content: space-between;
`;

export const PropertyDropdownContainer = styled.select`
  border:none;
  border-radius: 3px;
  background-color: black;
  color: white;
  padding: 5px;
  margin-right: 15px;
`;

export const PropertyValueContainer = styled.input`
  border: none;
  border-radius: 3px;
  background-color: black;
  color: white;
  padding: 5px;
  margin-right: 15px;
  flex-grow: 1;
`;

export const RemoveButtonContainer = styled.button`
  border: none;
  border-radius: 3px;
  background-color: #e32f52;
  color: white;
  width: 30px;
  &:hover {
    opacity: 0.75;
  }
`;
