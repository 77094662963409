import styled from "styled-components";

export const ReviewSubmissionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const MainVerticalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TitleContainer = styled.h1`
  font-size: 28px;
  margin-bottom: 25px;
`;
