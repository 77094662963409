import React from "react";

import CustomButton from "../../components/custom-button/custom-button.component";
import PropertyEntryReview from "../../components/property-entry-review/property-entry-review.component";

import {
  ActionButtonContainer,
  AddPropertyContainer,
  AddPropertyBorderContainer,
  AddPropertyButtonContainer,
  FormulaCheckContainer,
  FormulaContainer,
  FormulaDropdownContainer,
  FormulaEntryContainer,
  FormulaInputContainer,
  HorizontalContainer,
  HorizontalButtonContainer,
  InputFullContainer,
  InputContainer,
  InputGrowContainer,
  InputExampleContainer,
  ItemContainer,
  JournalInformationContainer,
  LinkContainer,
  MainVerticalContainer,
  MinorTitleContainer,
  PropertiesContainer,
  ReviewSubmissionsContainer,
  SourceContainer,
  SourceInputsContainer,
  TitleContainer
} from "./review-record-page.styles";

const ReviewRecord = () => (
  <ReviewSubmissionsContainer>
    <TitleContainer>REVIEW RECORD</TitleContainer>
    <MainVerticalContainer>
      <FormulaContainer>
        <MinorTitleContainer>FORMULA AND FORMULA PHASE</MinorTitleContainer>
        <FormulaEntryContainer>
          <FormulaInputContainer defaultValue="Al2_S3" placeholder="Chemical Formula"></FormulaInputContainer>
          <FormulaDropdownContainer>
            <option>Al2_S3 rt</option>
            <option>Al2_S3 ht</option>
            <option>new phase</option>
          </FormulaDropdownContainer>
          <FormulaCheckContainer>
            <ActionButtonContainer>View Records</ActionButtonContainer>
          </FormulaCheckContainer>
        </FormulaEntryContainer>
      </FormulaContainer>
      <PropertiesContainer>
        <MinorTitleContainer>PHYSICAL PROPERTIES</MinorTitleContainer>
        <PropertyEntryReview />
        <AddPropertyContainer>
          <AddPropertyBorderContainer>
            <AddPropertyButtonContainer>+</AddPropertyButtonContainer>
          </AddPropertyBorderContainer>
        </AddPropertyContainer>
      </PropertiesContainer>
      <SourceContainer>
        <MinorTitleContainer>REFERENCE</MinorTitleContainer>
        <SourceInputsContainer>
          <InputFullContainer>
            <LinkContainer>
              <InputGrowContainer
                defaultValue="https://link.aps.org/doi/10.1103/PhysRev.47.777"
                placeholder="URL or DOI Link"
              ></InputGrowContainer>
              <ActionButtonContainer>Autofill</ActionButtonContainer>
            </LinkContainer>
            <InputExampleContainer>
              e.g. https://link.aps.org/doi/10.1103/PhysRev.47.777
            </InputExampleContainer>
          </InputFullContainer>
          <InputFullContainer>
            <InputContainer
              required
              defaultValue="Einstein, A. and Podolsky, B. and Rosen, N."
              placeholder="Authors"
            ></InputContainer>
            <InputExampleContainer>
              e.g. Einstein, A. and Podolsky, B. and Rosen, N.
            </InputExampleContainer>
          </InputFullContainer>
          <InputFullContainer>
            <InputContainer
              required
              defaultValue="Phys. Rev."
              placeholder="Journal or Book Title"
            ></InputContainer>
            <InputExampleContainer>e.g. Phys. Rev.</InputExampleContainer>
          </InputFullContainer>
          <JournalInformationContainer>
            <InputFullContainer>
              <InputContainer
                required
                defaultValue="1935"
                placeholder="Year"
              ></InputContainer>
              <InputExampleContainer>e.g. 1935</InputExampleContainer>
            </InputFullContainer>
            <InputFullContainer>
              <InputContainer
                defaultValue="47"
                placeholder="Volume"
              ></InputContainer>
              <InputExampleContainer>e.g. 47</InputExampleContainer>
            </InputFullContainer>
            <InputFullContainer>
              <InputContainer
                defaultValue="777"
                placeholder="Pages or Article #"
              ></InputContainer>
              <InputExampleContainer>e.g. 777</InputExampleContainer>
            </InputFullContainer>
          </JournalInformationContainer>
          <HorizontalContainer>
            <ActionButtonContainer>Upload PDF</ActionButtonContainer>
            <ActionButtonContainer>View PDF</ActionButtonContainer>
          </HorizontalContainer>
        </SourceInputsContainer>
      </SourceContainer>
      <HorizontalButtonContainer>
        <ItemContainer>
          <CustomButton>APPROVE</CustomButton>
        </ItemContainer>
        <ItemContainer>
          <CustomButton>DENY</CustomButton>
        </ItemContainer>
      </HorizontalButtonContainer>
    </MainVerticalContainer>
  </ReviewSubmissionsContainer>
);

export default ReviewRecord;
