import React from "react";
import { withRouter } from "react-router-dom";

import CustomButton from "../../components/custom-button/custom-button.component";

import {
  ReviewSubmissionsContainer,
  MainVerticalContainer,
  TitleContainer
} from "./dashboard-review-submission.styles";

class DashboardReviewSubmissions extends React.Component {
  render() {
    const { history } = this.props;
    return (
      <ReviewSubmissionsContainer>
        <TitleContainer>REVIEW SUBMISSIONS</TitleContainer>
        <MainVerticalContainer>
          <CustomButton onClick={() => history.push(`/review-record`)}>
            Review
          </CustomButton>
        </MainVerticalContainer>
      </ReviewSubmissionsContainer>
    );
  }
}

export default withRouter(DashboardReviewSubmissions);
