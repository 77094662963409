import React from 'react';

import {
    // HeaderBlockContainer,
    ResultsOverviewContainer,
    // SearchOverviewHeaderContainer,
    TitleContainer
  } from "./results-overview.styles";

const ResultsOverview = () => (
    <ResultsOverviewContainer>
    <TitleContainer>RESULTS</TitleContainer>
    {/* <SearchOverviewHeaderContainer>
      <HeaderBlockContainer>
        <span>Parameter</span>
      </HeaderBlockContainer>
      <HeaderBlockContainer>
        <span>Value</span>
      </HeaderBlockContainer>
    </SearchOverviewHeaderContainer> */}
    {/* TODO: Logic to create additional search queries */}

  </ResultsOverviewContainer>
);

export default ResultsOverview;