import styled from "styled-components";

export const RemoveButtonContainer = styled.button`
  border: none;
  border-radius: 3px;
  background: #e32f52;
  color: white;
  width: 30px;
  &:hover {
    opacity: 0.75;
  }
`;

export const SearchQueryContainer = styled.div`
  border-radius: 5px;
  box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
  background-color: white;
  padding: 15px;
  margin: 5px 0px 5px 0px;
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const SearchDropdownContainer = styled.select`
  border: none;
  border-radius: 3px;
  background: black;
  color: white;
  padding: 5px;
  margin-right: 15px;
`;

export const SearchValueContainer = styled.input`
  border: none;
  border-radius: 3px;
  background: black;
  color: white;
  padding: 5px;
  margin-right: 15px;
  flex-grow: 1;
`;
