import React from "react";

import {
  PropertyEntryContainer,
  PropertyDropdownContainer,
  PropertyValueContainer,
  RemoveButtonContainer
} from "./property-entry-review.styles";

const PropertyEntryReview = () => (
  <PropertyEntryContainer>
    <PropertyDropdownContainer>
      <option>Curie temperature [K]</option>
      <option>Neel temperature [K]</option>
      <option>Superconducting temperature [K]</option>
      <option>Entry prototype</option>
      <option>SGR symbol (std.)</option>
      <option>SGR number (std.)</option>
      <option>Inv. center</option>
      <option>Polar axis</option>
      <option>V (std.) [nm...]</option>
      <option>a [nm]</option>
      <option>b [nm]</option>
      <option>c [nm]</option>
      <option>alpha [degree]</option>
      <option>beta [degree]</option>
      <option>gamma [degree]</option>
      <option>Density (calc.) [Mg m%...]</option>
      <option>Crystal system (std.)</option>
      <option>Compound class</option>
    </PropertyDropdownContainer>
    <PropertyValueContainer defaultValue="132"></PropertyValueContainer>
    <RemoveButtonContainer>-</RemoveButtonContainer>
  </PropertyEntryContainer>
);

export default PropertyEntryReview;
