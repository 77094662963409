import React from "react";

import DashboardReviewSubmissions from "../../components/dashboard-review-submissions/dashboard-review-submissions.component";

import {
  DashboardMenuContainer,
  DashboardMenuButtonContainer,
  HomeContainer,
  MainRowContainer,
  MinorTitleContainer,
  ProfilePageContainer,
  ReviewSubmissionsContainer,
  ReviewUserLevelsContainer,
  TitleContainer
} from "./profile-page.styles";

class ProfilePage extends React.Component {
  state = {
    dashboardFocus: "home"
  };

  handleClick(newFocus) {
    this.setState({
      dashboardFocus: newFocus
    });
  }

  render() {
    return (
      <ProfilePageContainer>
        <TitleContainer>DASHBOARD</TitleContainer>
        <MainRowContainer>
          <DashboardMenuContainer>
            <MinorTitleContainer></MinorTitleContainer>
            <DashboardMenuButtonContainer
              onClick={() => this.handleClick("home")}
            >
              Home
            </DashboardMenuButtonContainer>
            <DashboardMenuButtonContainer
              onClick={() => this.handleClick("reviewSubmissions")}
            >
              Review Submissions
            </DashboardMenuButtonContainer>
            <DashboardMenuButtonContainer
              onClick={() => this.handleClick("reviewUserLevels")}
            >
              Review User Levels
            </DashboardMenuButtonContainer>
          </DashboardMenuContainer>
          {this.state.dashboardFocus === "home" ? (
            <HomeContainer>Home</HomeContainer>
          ) : (
            <div></div>
          )}
          {this.state.dashboardFocus === "reviewSubmissions" ? (
            <ReviewSubmissionsContainer>
              <DashboardReviewSubmissions />
            </ReviewSubmissionsContainer>
          ) : (
            <div></div>
          )}
          {this.state.dashboardFocus === "reviewUserLevels" ? (
            <ReviewUserLevelsContainer>
              Review User Levels
            </ReviewUserLevelsContainer>
          ) : (
            <div></div>
          )}
        </MainRowContainer>
      </ProfilePageContainer>
    );
  }
}

export default ProfilePage;
