import styled from "styled-components";

export const AddPropertyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const AddPropertyBorderContainer = styled.div`
  border-radius: 5px;
  box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
  background-color: white;
  padding: 15px;
  margin: 5px 0px 0px 0px;
  /* display: flex;
  align-self: flex-end; */
`;

export const AddPropertyButtonContainer = styled.button`
  border: none;
  border-radius: 3px;
  background-color: #6fe852;
  color: white;
  width: 30px;
  height: 26px;
  &:hover {
    opacity: 0.75;
  }
`;

export const ButtonContainer = styled.div`
  margin-top: 15px;
  margin-left: 10px;
`;

export const CompoundSearchContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CompoundSearchQueryContainer = styled.div`
  border-radius: 5px;
  box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
  background-color: white;
  padding: 15px;
  margin: 5px 0px 0px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const CompoundSearchTextContainer = styled.div`
  padding: 5px;
  margin-right: 15px;
  font-size: 14px;
`;

export const CompoundSearchValueContainer = styled.input`
  border: none;
  border-radius: 3px;
  background: black;
  color: white;
  padding: 5px;
  display: flex;
  flex-grow: 1;
`;

export const CompoundSearchTitleContainer = styled.h2`
  font-size: 20px;
  margin: 5px;
`;

export const MinorTitleContainer = styled.h2`
  font-size: 20px;
  margin: 5px;
`;

export const OutputItemsRowContainer = styled.div`
  width: 625px;
  border-radius: 5px;
  box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
  background-color: white;
  padding: 15px;
  margin: 5px 0px 0px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;

export const OutputItemsColumnContainer = styled.div`
`;

export const OutputSelectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 5px;
  /* padding: 5px; */
`;

export const QueriesContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* padding: 5px; */
`;

export const QueriesTitleContainer = styled.h2`
  font-size: 20px;
  margin: 5px;
`;

export const SearchContainer = styled.div`
  width: 625px;
  margin: 5px;
  display: flex;
  flex-direction: column;
`;

export const SearchMenuContainer = styled.div`
  border-radius: 5px;
  box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
  background-color: white;
  padding: 15px;
  margin: 5px;
  display: flex;
  flex-direction: column;
`;

export const SearchMenuButtonContainer = styled.button`
  border: 1px solid darkgrey;
  border-radius: 3px;
  background-color: #e1e1e1;
  margin-bottom: 15px;
  width: 150px;
  height: 26px;
  &:hover {
    opacity: 0.75;
  }
`;

export const SearchOverviewRowContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const SearchOverviewContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TitleContainer = styled.h1`
  font-size: 28px;
  margin-bottom: 25px;
`;
