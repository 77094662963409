import React from "react";
import { Route } from "react-router-dom";
// import { connect } from 'react-redux';

import ResultsOverview from "../../components/results-overview/results-overview.component";
import SearchOverview from "../../components/search-overview/search-overview.component";

class SearchPage extends React.Component {
  render() {
    const { match } = this.props;

    return (
      <div>
        <Route exact path={`${match.path}`} component={SearchOverview} />
        <Route path={`${match.path}/results`} component={ResultsOverview} />
      </div>
    );
  }
}

export default SearchPage;
