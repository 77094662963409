import React from "react";

import CustomButton from "../../components/custom-button/custom-button.component";
import PropertyEntry from "../../components/property-entry/property-entry.component";

import {
  ActionButtonContainer,
  AddPropertyContainer,
  AddPropertyBorderContainer,
  AddPropertyButtonContainer,
  ButtonContainer,
  ContributePageContainer,
  FormulaContainer,
  FormulaDropdownContainer,
  FormulaEntryContainer,
  FormulaInputContainer,
  InputFullContainer,
  InputContainer,
  InputGrowContainer,
  InputExampleContainer,
  JournalInformationContainer,
  LinkContainer,
  MainVerticalContainer,
  MinorTitleContainer,
  PropertiesContainer,
  SourceContainer,
  SourceInputsContainer,
  TitleContainer
} from "./contribute-page.styles";

const ContributePage = () => (
  <ContributePageContainer>
    <TitleContainer>ADD DATA</TitleContainer>
    <MainVerticalContainer>
      <FormulaContainer>
        <MinorTitleContainer>FORMULA AND FORMULA PHASE</MinorTitleContainer>
        <FormulaEntryContainer>
          <FormulaInputContainer placeholder="Chemical Formula"></FormulaInputContainer>
          <FormulaDropdownContainer>
            <option></option>
            <option>Al2_S3 rt</option>
            <option>Al2_S3 ht</option>
            <option>new phase</option>
          </FormulaDropdownContainer>
        </FormulaEntryContainer>
      </FormulaContainer>
      <PropertiesContainer>
        <MinorTitleContainer>PHYSICAL PROPERTIES</MinorTitleContainer>
        <PropertyEntry />
        <PropertyEntry />
        <PropertyEntry />
        <AddPropertyContainer>
          <AddPropertyBorderContainer>
            <AddPropertyButtonContainer>+</AddPropertyButtonContainer>
          </AddPropertyBorderContainer>
        </AddPropertyContainer>
      </PropertiesContainer>
      <SourceContainer>
        <MinorTitleContainer>REFERENCE</MinorTitleContainer>
        <SourceInputsContainer>
          <InputFullContainer>
            <LinkContainer>
              <InputGrowContainer placeholder="URL or DOI Link"></InputGrowContainer>
              <ActionButtonContainer>Autofill</ActionButtonContainer>
            </LinkContainer>
            <InputExampleContainer>e.g. https://link.aps.org/doi/10.1103/PhysRev.47.777</InputExampleContainer>
          </InputFullContainer>
          <InputFullContainer>
            <InputContainer required placeholder="Authors"></InputContainer>
            <InputExampleContainer>e.g. Einstein, A. and Podolsky, B. and Rosen, N.</InputExampleContainer>
          </InputFullContainer>
          <InputFullContainer>
            <InputContainer required placeholder="Journal or Book Title"></InputContainer>
            <InputExampleContainer>e.g. Phys. Rev.</InputExampleContainer>
          </InputFullContainer>
          <JournalInformationContainer>
            <InputFullContainer>
              <InputContainer required placeholder="Year"></InputContainer>
              <InputExampleContainer>e.g. 1935</InputExampleContainer>
            </InputFullContainer>
            <InputFullContainer>  
              <InputContainer placeholder="Volume"></InputContainer>
              <InputExampleContainer>e.g. 47</InputExampleContainer>
            </InputFullContainer>
            <InputFullContainer>
              <InputContainer placeholder="Pages or Article #"></InputContainer>
              <InputExampleContainer>e.g. 777</InputExampleContainer>
            </InputFullContainer>
          </JournalInformationContainer>
          <ActionButtonContainer>Upload PDF</ActionButtonContainer>
        </SourceInputsContainer>
      </SourceContainer>
      <ButtonContainer>
        <CustomButton>SUBMIT</CustomButton>
      </ButtonContainer>
    </MainVerticalContainer>
  </ContributePageContainer>
);

export default ContributePage;
