import styled from "styled-components";

export const ActionButtonContainer = styled.button`
  border: 1px solid darkgrey;
  border-radius: 3px;
  background-color: #e1e1e1;
  width: 100px;
  height: 26px;
  &:hover {
    opacity: 0.75;
  }
`;

export const AddPropertyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const AddPropertyBorderContainer = styled.div`
  border-radius: 5px;
  box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
  background-color: white;
  padding: 15px;
  margin: 5px 5px 0px 0px;
  display: flex;
`;

export const AddPropertyButtonContainer = styled.button`
  border: none;
  border-radius: 3px;
  background-color: #6fe852;
  color: white;
  width: 30px;
  height: 26px;
  &:hover {
    opacity: 0.75;
  }
`;

export const FormulaCheckContainer = styled.div`
  display: flex;
  align-self: center;
`;

export const FormulaContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FormulaEntryContainer = styled.div`
  border-radius: 5px;
  box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
  background-color: white;
  padding: 15px;
  margin: 5px;
  width: 800px;
  display: flex;
  justify-content: flex-start;
`;

export const FormulaDropdownContainer = styled.select`
  border: none;
  border-radius: 3px;
  background-color: black;
  color: white;
  padding: 5px;
  margin-right: 15px;
`;

export const FormulaInputContainer = styled.input`
  border: none;
  border-radius: 3px;
  width: 175px;
  background-color: black;
  color: white;
  padding: 5px;
  margin-right: 15px;
`;

export const HorizontalContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 215px;
`;

export const HorizontalButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: flex-end;
  width: 350px;
  margin-top: 15px;
`;

export const ItemContainer = styled.div`
`;

// Contains input field and example
// InputContainer/InputGrowContainer + InputExampleContainer
export const InputFullContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
`;

export const InputContainer = styled.input`
  border: none;
  border-radius: 3px;
  background-color: black;
  color: white;
  padding: 5px;
  margin-right: 15px;
  margin-bottom: 0px;
`;

export const InputGrowContainer = styled.input`
  border: none;
  border-radius: 3px;
  background-color: black;
  color: white;
  padding: 5px;
  margin-right: 15px;
  margin-bottom: 0px;
  flex-grow: 1;
`;

export const InputExampleContainer = styled.div`
  font-size: 10px;
`;

export const JournalInformationContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const LinkContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const MainVerticalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const MinorTitleContainer = styled.h1`
  font-size: 20px;
  margin: 5px;
`;

export const PropertiesContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ReviewSubmissionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SourceContainer = styled.div`
  /* display: flex;
  flex-direction: column; */
`;

export const SourceInputsContainer = styled.div`
  border-radius: 5px;
  box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
  background-color: white;
  padding: 15px;
  margin: 5px;
  width: 800px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: space-between;
  justify-items: space-between;
`;

export const TitleContainer = styled.h1`
  font-size: 28px;
  margin-bottom: 25px;
`;
