import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import Header from './components/header/header.component';

import ContributePage from './pages/contribute-page/contribute-page.component';
import HomePage from './pages/home-page/home-page.component';
import PortalPage from './pages/portal-page/portal-page.component';
import ProfilePage from './pages/profile-page/profile-page.component';
import ReviewRecordPage from './pages/review-record-page/review-record-page';
import SearchPage from './pages/search-page/search-page.component';

import { auth, createUserProfileDocument } from './firebase/firebase.utils';

import { selectCurrentUser } from './redux/user/user.selectors';
import { setCurrentUser } from './redux/user/user.actions';

import './App.css';

class App extends React.Component { 
  unsubscribeFromAuth = null;
  
  componentDidMount() {
    const { setCurrentUser } = this.props;
    this.unsubscribeFromAuth = auth.onAuthStateChanged(async userAuth => {
      if (userAuth) {
        const userRef = await createUserProfileDocument(userAuth);

        userRef.onSnapshot(snapShot => {
          setCurrentUser({
            id: snapShot.id,
            ...snapShot.data()
          });
        });
      } else {
        setCurrentUser(userAuth);
      }
    });
  }

  componentWillUnmount() {
    this.unsubscribeFromAuth();
  }

  render() {
    return (
      <div>
        <Header />
        <Switch>
          <Route exact path='/' component={HomePage} />
          <Route path='/search' component={SearchPage} />
          <Route path='/contribute' component={ContributePage} />
          <Route path='/review-record' component={ReviewRecordPage} />
          <Route exact path='/portal' render={() =>
            this.props.currentUser ? (
              <Redirect to='/search' />
            ) : (
                <PortalPage />
              )}
          />
           {/* TODO: Redirect to PortalPage if not logged in.
           (Problem: setcurrentuser not called until too late)
           <Route exact page='/profile' render={() =>
            this.props.currentUser ? (
              <ProfilePage />
            ) : (
              <Redirect to='/portal' />
            )}
          /> */}
          <Route exact path='/profile' component={ProfilePage} />
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser
})

const mapDispatchToProps = dispatch => ({
  setCurrentUser: user => dispatch(setCurrentUser(user))
});

export default connect(mapStateToProps, mapDispatchToProps)(App);