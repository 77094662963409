import styled from "styled-components";

export const DashboardMenuContainer = styled.div`
  border-radius: 5px;
  box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
  background-color: white;
  padding: 15px;
  margin: 5px;
  display: flex;
  flex-direction: column;
`;

export const DashboardMenuButtonContainer = styled.button`
  border: 1px solid darkgrey;
  border-radius: 3px;
  background-color: #e1e1e1;
  margin-bottom: 15px;
  width: 150px;
  height: 26px;
  &:hover {
    opacity: 0.75;
  }
`;

export const HomeContainer = styled.div`
  border-radius: 5px;
  box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
  background-color: white;
  padding: 15px;
  margin: 5px;
  width: 625px;
  display: flex;
  flex-direction: column;
`;

export const MainRowContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const MinorTitleContainer = styled.h1`
  font-size: 20px;
  margin: 5px;
`;

export const ProfilePageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ReviewSubmissionsContainer = styled.div`
  border-radius: 5px;
  box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
  background-color: white;
  padding: 15px;
  margin: 5px;
  width: 625px;
  display: flex;
  flex-direction: column;
`;

export const ReviewUserLevelsContainer = styled.div`
  border-radius: 5px;
  box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
  background-color: white;
  padding: 15px;
  margin: 5px;
  width: 625px;
  display: flex;
  flex-direction: column;
`;

export const TitleContainer = styled.h1`
  font-size: 28px;
  margin-bottom: 25px;
`;
