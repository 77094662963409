import React from "react";
// import Select from "react-select";

// import FormInput from "../form-input/form-input.component";

import {
  SearchQueryContainer,
  SearchDropdownContainer,
  SearchValueContainer,
  RemoveButtonContainer
} from "./search-query.styles";

// const options = [
//   { value: 'elementsContain', label: 'Contains all of these elements'},
//   { value: 'elementsDoesNotContain', label: 'Does not contain any of these elements'},
//   { value: 'elementsContainsAtLeastOne', label: 'Contains at least 1 of these elements '},
//   { value: 'elementsContainsOnlyAtLeastOne', label: 'Contains only at least 1 of these elements'},
//   { value: 'elementsNumberOf', label: 'Number of elements in compound'},
//   { value: 'onlyIntegers', label: 'Only integers'},
//   { value: 'crystalSystem', label: 'Crystal system'},
//   { value: 'crystalSystemExclude', label: 'Exclude these crystal systems'},
//   { value: 'spaceGroupNumber', label: 'Space group number'},
//   { value: 'polarAxis', label: 'Polar axis'},
//   { value: 'inversionCenter', label: 'Inversion center'},
//   { value: 'curieTempGreater', label: 'Curie temperature >'},
//   { value: 'curieTempLess', label: 'Curie temperature <'},
//   { value: 'neelTempGreater', label: 'Neel temperature >'},
//   { value: 'neelTempLess', label: 'Neel temperature <'},
//   { value: 'superconductingTempGreater', label: 'Superconducting temperature >'},
//   { value: 'superconductingTempLess', label: 'Superconducting temperature <'}
// ]

const SearchQuery = () => (
  <SearchQueryContainer>
    {/* NOTE: use <select> tag to make drop-down in form */}
    {/* <SearchDropdownContainer>
      Search Dropdown
      <form onSubmit={this.handleSubmit}>
        <FormInput
          name="value"
          type="value"
          label="value"
        //   value={parameter}
          handleChange={this.handleChange}
          required
        />
      </form>
    </SearchDropdownContainer>
    <SearchValueContainer>
      Value
      <form onSubmit={this.handleSubmit}>
        <FormInput
          name="value"
          type="value"
          label="value"
          //   value={parameter}
          handleChange={this.handleChange}
          required
        />
      </form>
    </SearchValueContainer> */}
    <SearchDropdownContainer>
      <option value="elementsContain">Contains all of these elements</option>
      <option value="elementsDoesNotContain">Does not contain any of these elements</option>
      <option value="elementsContainsAtLeastOne">Contains at least 1 of these elements</option>
      <option value="elementsContainsOnlyAtLeastOne">
        Contains only at least 1 of these elements
      </option>
      <option value="elementsNumberOf">Number of elements in compound</option>
      <option value="onlyIntegers">Only integers</option>
      <option value="crystalSystem">Crystal system</option>
      <option value="crystalSystemExclude">Exclude these crystal systems</option>
      <option value="spaceGroupNumber">Space group number</option>
      <option value="polarAxis">Polar axis</option>
      <option value="inversionCenter">Inversion center</option>
      <option value="curieTempGreater">Curie temperature ≥</option>
      <option value="curieTempLess">Curie temperature ≤</option>
      <option value="neelTempGreater">Neel temperature ≥</option>
      <option value="neelTempLess">Neel temperature	≤</option>
      <option value="superconductingTempGreater">Superconducting temperature ≥</option>
      <option value="superconductingTempLess">Superconducting temperature	≤</option>
    </SearchDropdownContainer>
    <SearchValueContainer></SearchValueContainer>
    <RemoveButtonContainer>-</RemoveButtonContainer>
  </SearchQueryContainer>
);

export default SearchQuery;
