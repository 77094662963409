import React from "react";

import {
  PropertyEntryContainer,
  PropertyDropdownContainer,
  PropertyValueContainer,
  RemoveButtonContainer
} from "./property-entry.styles";

const PropertyEntry = () => (
  <PropertyEntryContainer>
    <PropertyDropdownContainer>
      <option>Curie temperature</option>
      <option>Neel temperature</option>
      <option>Superconducting temperature</option>
      <option>Entry prototype</option>
      <option>SGR symbol (std.)</option>
      <option>SGR number (std.)</option>
      <option>Inv. center</option>
      <option>Polar axis</option>
      <option>V (std.) [nm...]</option>
      <option>a [nm]</option>
      <option>b [nm]</option>
      <option>c [nm]</option>
      <option>alpha [degree]</option>
      <option>beta [degree]</option>
      <option>gamma [degree]</option>
      <option>Density (calc.) [Mg m%...]</option>
      <option>Crystal system (std.)</option>
      <option>Compound class</option>
    </PropertyDropdownContainer>
    <PropertyValueContainer></PropertyValueContainer>
    <RemoveButtonContainer>-</RemoveButtonContainer>
  </PropertyEntryContainer>
);

export default PropertyEntry;
